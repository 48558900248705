'use strict'

const RSVP = require('es6-promise').Promise

const app = require('./../modules/app.es6')
const api = require('./../components/api.es6')
const gravatar = require('./../components/gravatar.es6')
const metadata = require('./../components/metadata.es6')
const analytics = require('./../../utils/analytics.es6')
const views = require('./../views.es6')

let hasAccessTo = []
let preppedMenu = []
let parentGroupTree = []

export const renderNavigation = () => {
  return new RSVP.Promise((resolve, reject) => {
    apiFetchPermissions()
      .then(() => {
        return apiFetchMenu()
      })
      .then((rawMenu) => {
        parentGroupTree = app.buildParentGroupTree()
        return prepMenu(rawMenu)
      })
      .then(() => {
        trackCustomData()
        return app.setupUserAndGroupConfigs()
      })
      .then(() => {
        $('#navigation-menu').replaceWith(
          views.render('navigation', {
            groupId: app.groupId,
            menu: preppedMenu,
            gravatarHtml: gravatar.generateHtml(
              localStorage.getItem('user-email'),
              32
            ),
            fullName: localStorage.getItem('user-fullname'),
            email: localStorage.getItem('user-email'),
            groupName: app.groupName,
            groupLevel: app.groupLevel,
            isOwner: app.isOwner,
            isUserAdmin: app.isUserAdmin,
            isMaker: app.isMaker,
            isContactAdmin: app.isContactAdmin,
            isMakerAccount: app.isMakerAccount,
            isApplication: app.isApplication,
            isOrganization: app.isOrganization,
          })
        )
        resolve()
      })
  })
}

const apiFetchPermissions = () => {
  return new RSVP.Promise((resolve, reject) => {
    api.send(`/apiv3/permissions/${app.groupId}`).then((data) => {
      hasAccessTo = []
      let concisePermissions = {}
      const defaultPermission = {
        hasAccess: false,
        viewAll: false,
        start: false,
        continue: false,
        audits: false,
        historical: false,
        delete: false,
        customize: false,
      }

      _.each(data.permissions, (permission) => {
        if (concisePermissions[permission.tactivityId] == undefined)
          concisePermissions[permission.tactivityId] = Object.assign(
            {},
            defaultPermission
          )
        if (permission.hasAccess) {
          concisePermissions[permission.tactivityId].hasAccess = true
          hasAccessTo.push(permission.tactivityId)
        }
        if (permission.viewAll)
          concisePermissions[permission.tactivityId].viewAll = true
        if (permission.start)
          concisePermissions[permission.tactivityId].start = true
        if (permission.continue)
          concisePermissions[permission.tactivityId].continue = true
        if (permission.audits)
          concisePermissions[permission.tactivityId].audits = true
        if (permission.historical)
          concisePermissions[permission.tactivityId].historical = true
        if (permission.delete)
          concisePermissions[permission.tactivityId].delete = true
        if (permission.customize)
          concisePermissions[permission.tactivityId].customize = true
      })

      app.permissions = concisePermissions
      resolve()
    })
  })
}

const apiFetchMenu = () => {
  return new RSVP.Promise((resolve, reject) => {
    let menu = []
    const findByRegexes = [/app-config/g]
    metadata
      .refreshUserMetadata(app.groupId, findByRegexes, true)
      .then((appConfiguration) => {
        if (
          appConfiguration !== undefined &&
          appConfiguration.length > 0 &&
          appConfiguration[0].menu !== undefined &&
          appConfiguration[0].menu.length > 0
        ) {
          localStorage.setItem(
            'app-config',
            JSON.stringify(appConfiguration[0])
          )
          resolve(appConfiguration[0].menu)
        } else {
          api
            .send(`/apiv3/reports/${app.groupId}/taskflowReports`)
            .then((data) => {
              return _.map(data.taskflowReports, (report) => {
                return {
                  title: report.label,
                  url: `/hub/$groupId/report/${report.id}/`,
                }
              })
            })
            .then((subTaskflows) => {
              menu.push({
                title: 'Live Workflows',
                sub: _.sortBy(subTaskflows, 'title'),
              })
              localStorage.setItem('app-config', JSON.stringify({ menu: menu }))
              resolve(menu)
            })
        }
      })
  })
}

const prepMenu = (rawMenu) => {
  return new RSVP.Promise((resolve, reject) => {
    let appConfiguration = {
      menu: [],
    }
    if (localStorage.getItem('app-config') !== null)
      appConfiguration = JSON.parse(localStorage.getItem('app-config'))

    preppedMenu = []
    _.each(rawMenu, (menuItem) => {
      const processedItem = processMenuPermissions(menuItem)
      if (processedItem) preppedMenu.push(processedItem)
    })
    appConfiguration.menu = preppedMenu
    localStorage.setItem('app-config', JSON.stringify(appConfiguration))
    resolve(preppedMenu)
  })
}

const processMenuPermissions = (item) => {
  if (item.url !== undefined) {
    let matched = false
    if (item.url.indexOf(`/todos/`) !== -1) {
      matched = true
    } else {
      _.each(hasAccessTo, (id) => {
        if (item.url.indexOf(`/report/${id}/`) !== -1) {
          matched = true
        } else if (item.url.indexOf(`/profiles/${id}/`) !== -1) {
          matched = true
        }
        if (
          item.parentGroupAccess !== undefined &&
          item.parentGroupAccess.length > 0 &&
          parentGroupTree.length > 0
        ) {
          matched = parentGroupTree.some((g) =>
            item.parentGroupAccess.includes(g)
          )
        }
      })
    }
    item.url = item.url.replace(/\$groupId/i, app.groupId)
    return matched ? item : false
  } else if (item.sub !== undefined) {
    let subItems = []
    _.each(item.sub, (subItem) => {
      const processedSubItem = processMenuPermissions(subItem)
      if (processedSubItem) subItems.push(processedSubItem)
    })
    item.sub = subItems
    return item.sub.length > 0 ? item : false
  }
}

export const getActiveMenuItem = () => {
  const appConfiguration = JSON.parse(localStorage.getItem('app-config'))
  const currentUrlHash = window.location.hash.substr(2)
  let activeMenuItem = false
  _.each(appConfiguration.menu, (item) => {
    let activeItem = checkIfActiveMenuItem(item, currentUrlHash)
    if (activeItem) activeMenuItem = activeItem
  })
  return activeMenuItem
}

const checkIfActiveMenuItem = (item, currentUrlHash) => {
  let activeMenuItem = false
  if (item.url !== undefined) {
    if (item.url.indexOf(currentUrlHash) !== -1) {
      activeMenuItem = item
    }
  } else if (item.sub !== undefined) {
    _.each(item.sub, (subItem) => {
      const activeSubItem = checkIfActiveMenuItem(subItem, currentUrlHash)
      if (activeSubItem) activeMenuItem = activeSubItem
    })
  }
  return activeMenuItem
}

const trackCustomData = () => {
  return new RSVP.Promise((resolve, reject) => {
    const appConfiguration = JSON.parse(localStorage.getItem('app-config'))
    if (appConfiguration.analytics !== undefined) {
      const trackingMetadata = { groupId: app.groupId }
      _.each(appConfiguration.analytics, (event) => {
        analytics.trackEvent(event, trackingMetadata)
      })
    }
    resolve()
  })
}
