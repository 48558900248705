'use strict'

const RSVP = require('es6-promise').Promise

const api = require('./../components/api.es6')
const analytics = require('./../../utils/analytics.es6')
const hubpubsub = require('./../../utils/hubpubsub.es6')
const views = require('./../views.es6')

export const enableDeleteButton = (kind, groupId, taskflowId) => {
  $('.btn-delete-js').popup({
    popup: '#reusable-popup.popup',
    preserve: true,
    movePopup: true,
    exclusive: true,
    variation: 'wide',
    position: 'bottom center',
    on: 'click',

    onShow: () => {
      const count = $('.dataTable').DataTable().rows('.selected').ids().length

      let popupView = ''
      if (kind == 'people') {
        popupView =
          count == 0
            ? 'people/delete-no-items-popup'
            : 'people/delete-items-popup'
      } else {
        popupView =
          count == 0
            ? 'reports/delete-no-items-popup'
            : 'reports/delete-items-popup'
      }
      $('#reusable-popup.ui.popup').html(
        views.render(popupView, {
          count: count,
        })
      )
    },

    onVisible: () => {
      $('#reusable-popup .delete-btn-js').on('click', (e) => {
        e.preventDefault()
        $('#reusable-popup .delete-btn-js').addClass('loading')

        if (kind == 'people') {
          const peopleIdsToDelete = []
          $('.dataTable')
            .DataTable()
            .rows('.selected.row-person')
            .ids()
            .map((id) => {
              peopleIdsToDelete.push(id)
            })
          const groupIdsToDelete = []
          $('.dataTable')
            .DataTable()
            .rows('.selected.row-group')
            .ids()
            .map((id) => {
              groupIdsToDelete.push(id)
            })

          apiDeletePeople(groupId, peopleIdsToDelete).then(() => {
            apiDeleteGroups(groupId, groupIdsToDelete).then(() => {
              $('.dataTable').DataTable().rows('.selected').remove().draw()
              $('.btn-delete-js').popup('hide')
            })
          })
        } else {
          const processIdsToDelete = []
          $('.dataTable')
            .DataTable()
            .rows('.selected')
            .ids()
            .map((id) => {
              processIdsToDelete.push(parseInt(id))
            })
          apiDeleteProcesses(groupId, taskflowId, processIdsToDelete).then(
            () => {
              $('.dataTable').DataTable().rows('.selected').remove().draw()
              $('.btn-delete-js').popup('hide')
            }
          )
        }
      })
      $('#reusable-popup .close-popup').on('click', (e) => {
        $('.btn-delete-js').popup('hide')
      })
    },

    onHidden: () => {
      $('#reusable-popup .delete-btn-js').off('click')
      $('#reusable-popup .close-popup').off('click')
      $('#reusable-popup.ui.popup').html(
        views.render('components/empty-popup', {})
      )
    },
  })
}

const apiDeleteProcesses = (groupId, taskflowId, processIds) => {
  return new RSVP.Promise((resolve, reject) => {
    if (processIds.length == 0) resolve()
    const opts = {
      contentType: 'application/json',
      dataType: 'text',
    }
    api
      .send(
        `/api/group/${groupId}/taskflows/${taskflowId}`,
        'DELETE',
        processIds,
        opts
      )
      .then((data) => {
        const trackingMetadata = {
          groupId: groupId,
          taskflowId: taskflowId,
          processIds: processIds.join(','),
        }
        analytics.trackEvent('Deleted taskflow instances', trackingMetadata)
        hubpubsub.publish('notification.show', {
          kind: 'green',
          message: 'The selected entries have been deleted.',
        })
        resolve(data)
      })
  })
}

const apiDeletePeople = (groupId, ids) => {
  return new RSVP.Promise((resolve, reject) => {
    if (ids.length > 0) {
      const numericIds = ids.map((id) => {
        return parseInt(id)
      })
      const trackingMetadata = { groupId: groupId, ids: numericIds.join(',') }
      analytics.trackEvent('Deleted people', trackingMetadata)
      hubpubsub.publish('notification.show', {
        kind: 'green',
        message:
          'The selected people have been queued for deletion and will be removed sometime within the next 2 hours.',
      })

      const evnts = []
      ids.forEach((str) => {
        const [id, label] = str.split('_')
        evnts.push({
          key: 'user-deleted',
          label: `{user.firstname} {user.lastname} removed ${label} from {group.label}`,
          icon: '➖🧑🏻‍🦲',
        })
        api.send(`/hub/q/add/deletion`, 'POST', [
          { uriParams: `${groupId}/${id}` },
        ])
      })
      api.send(`/apiv3/events/${groupId}/log`, 'POST', evnts, {
        dataType: 'text',
      })
      resolve()
    } else {
      resolve()
    }
  })
}

const apiDeleteGroups = (groupId, ids) => {
  return new RSVP.Promise((resolve, reject) => {
    if (ids.length > 0) {
      const numericIds = ids.map((id) => {
        return parseInt(id)
      })
      const opts = {
        contentType: 'application/json',
        dataType: 'text',
      }
      api
        .send(`/apiv3/group/${groupId}/groups`, 'DELETE', numericIds, opts)
        .then((data) => {
          const trackingMetadata = {
            groupId: groupId,
            ids: numericIds.join(','),
          }
          analytics.trackEvent('Deleted groups', trackingMetadata)
          hubpubsub.publish('notification.show', {
            kind: 'green',
            message: 'The selected organizations/teams have been deleted.',
          })

          const evnts = []
          ids.forEach((str) => {
            const [id, label] = str.split('_')
            evnts.push({
              key: 'group-deleted',
              label: `{user.firstname} {user.lastname} removed ${label} from {group.label}`,
              icon: '➖🏢',
            })
          })
          api.send(`/apiv3/events/${groupId}/log`, 'POST', evnts, {
            dataType: 'text',
          })

          resolve(data)
        })
    } else {
      resolve()
    }
  })
}
