'use strict'

const RSVP = require('es6-promise').Promise

const api = require('./../components/api.es6')
const roles = require('./../components/roles.es6')
const gravatar = require('./../components/gravatar.es6')
const analytics = require('./../../utils/analytics.es6')
const hubpubsub = require('./../../utils/hubpubsub.es6')
const modalComponent = require('./../../utils/modal.es6')
const views = require('./../views.es6')

const upsert = (baseGroupId, currentGroupId, currentRoles) => {
  const groupId = parseInt($('#reusable-modal form [name=groupId]').val())
  const id = $('#reusable-modal form [name=id]').val()
  const firstname = $('#reusable-modal form [name=firstname]').val()
  const lastname = $('#reusable-modal form [name=lastname]').val()
  const email = $('#reusable-modal form [name=email]').val()
  const username = $('#reusable-modal form [name=username]').val()
  const mobile = $('#reusable-modal form [name=mobile]').val()
  const selectedRoles = _.map(
    $('#reusable-modal form [name=roles]:checked'),
    (r) => {
      return parseInt($(r).val())
    }
  )
  const sendEmail = $('#reusable-modal form [name=sendEmail]:checked').val()

  if (firstname == '' || lastname == '' || email == '') {
    $('#reusable-modal .content .kotive-validation-message').removeClass(
      'hidden'
    )
    $('#reusable-modal .content').scrollTop(0)
    return false
  }

  const action = !isNaN(parseInt(id)) ? 'updated' : 'added'

  const currentRoleIds = _.map(currentRoles, (r) => {
    return r.roleId
  })
  const rolesToAdd = _.difference(selectedRoles, currentRoleIds)
  const rolesToDelete = _.difference(currentRoleIds, selectedRoles)

  addOrUpdatePerson(id, username, firstname, lastname, email, mobile, groupId)
    .then((data) => {
      _.each(rolesToAdd, (roleId) => {
        const personRole = {
          personRole: {
            userId: data.person.id,
            roleId: roleId,
            groupId: groupId,
          },
        }
        api.send(`/api/personRoles`, 'POST', personRole)
      })

      _.each(rolesToDelete, (roleId) => {
        const roleToDelete = _.find(currentRoles, (role) => {
          return role.roleId == roleId
        })
        api.send(`/api/personRoles/${roleToDelete.id}`, 'DELETE')
      })

      hubpubsub.publish('notification.show', {
        kind: 'green',
        message: `The person has been ${action}.`,
      })

      if (sendEmail !== undefined) {
        if (action == 'added') {
          api.send(
            `/apiv3/user/${data.person.id}/invite/to/${groupId}`,
            'POST',
            false,
            { dataType: 'text' }
          )
          const trackingMetadata = { to: data.person.email }
          analytics.trackEvent('Sent an invite email', trackingMetadata)
        } else if (action == 'updated') {
          api.send(
            `/api/user/resetpassword`,
            'POST',
            { email: email.trim().toLowerCase() },
            { dataType: 'text' }
          )
          const trackingMetadata = { to: data.person.email }
          analytics.trackEvent('Sent a password reset email', trackingMetadata)
        }
        hubpubsub.publish('notification.show', {
          kind: 'green',
          message: `An invitation email has been sent.`,
        })
      }

      if (action == 'added') {
        const obj = [
          '',
          gravatar.generateHtml(data.person.email, 32),
          `${data.person.firstname} ${data.person.lastname}`,
          data.person.email,
          data.person.mobile,
          '',
          'Person',
          `<a href='${currentGroupId}/person/${data.person.id}/${baseGroupId}'>View profile &rarr;</a>`,
        ]
        obj[
          'DT_RowId'
        ] = `${data.person.id}_${data.person.firstname} ${data.person.lastname}`
        obj['DT_RowClass'] = 'row-person'
        $('.dataTable').DataTable().row.add(obj).draw()
      } else if (action == 'updated') {
        onExit()
        hubpubsub.publish('person.refreshBadge')
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const addOrUpdatePerson = (
  id,
  username,
  firstname,
  lastname,
  email,
  mobile,
  groupId
) => {
  return new RSVP.Promise((resolve, reject) => {
    const personData = {
      person: {
        firstname: firstname,
        lastname: lastname,
        email: email.trim().toLowerCase(),
        username: username.trim().toLowerCase(),
        mobile: mobile,
        password: '',
        groupId: groupId,
      },
    }
    if (id == '') {
      personData.person.username = personData.person.email
      api.send(`/api/people`, 'POST', personData).then((data) => {
        analytics.trackEvent('Added person', {
          groupId: groupId,
          personId: data.person.id,
        })
        api.send(
          `/apiv3/events/${groupId}/log`,
          'POST',
          [
            {
              key: 'user-added',
              label: `{user.firstname} {user.lastname} added ${firstname} ${lastname} to {group.label}`,
              icon: '➕🧑🏻‍🦲',
            },
          ],
          { dataType: 'text' }
        )
        resolve(data)
      })
    } else {
      personData.person.id = parseInt(id)
      api.send(`/api/people`, 'PUT', personData).then((data) => {
        analytics.trackEvent('Updated person', {
          groupId: groupId,
          personId: id,
        })
        api.send(
          `/apiv3/events/${groupId}/log`,
          'POST',
          [
            {
              key: 'user-updated',
              label: `{user.firstname} {user.lastname} updated ${firstname} ${lastname}'s user account in {group.label}`,
              icon: '🧑🏻‍🦲',
            },
          ],
          { dataType: 'text' }
        )
        resolve(data)
      })
    }
  })
}

const setupEvents = (
  baseGroupId,
  currentGroupId,
  currentGroupStructure,
  currentPerson,
  currentRoles
) => {
  $(
    '#add-new-people-js .ui.dropdown, #add-new-groups-js .ui.dropdown'
  ).dropdown()

  $('body').on(
    'click',
    "[data-target='modal-person-new'], [data-target='modal-person-update']",
    (e) => {
      e.preventDefault()

      const isNew =
        $(e.target).data('target') == 'modal-person-new' ? true : false
      const currentGroup = currentGroupStructure
        ? _.findWhere(currentGroupStructure, { id: parseInt(currentGroupId) })
        : false

      modalComponent.resizeHeight('#reusable-modal')

      if (currentPerson === undefined) {
        currentPerson = {
          id: '',
          firstname: '',
          lastname: '',
          email: '',
          mobile: '',
        }
      }
      if (currentRoles == undefined) currentRoles = {}

      $('#reusable-modal')
        .modal({
          duration: 10,
          selector: {
            deny: '.close-modal, .actions .close-modal',
            approve: '.actions .positive, .actions .approve, .actions .ok',
          },
          onVisible: () => {
            let header = isNew ? `Add a new person` : `Update the person`
            if (currentGroup) header += ` in ${currentGroup.label}`
            $('#reusable-modal .header').html(header)

            const currentRoleIds = _.map(currentRoles, (r) => {
              return r.roleId
            })

            roles
              .getAvailableRoles(currentGroupId)
              .then((availableRoles) => {
                // remove Account owner (1) and Maker (3) roles until we can have multiple Makers in Designer (for correct sync)
                availableRoles = _.reject(availableRoles, (r) => {
                  return [1, 3].indexOf(r.roleId) !== -1
                })

                $('#reusable-modal .content').html(
                  views.render('people/person-add-update', {
                    isNew: isNew,
                    group: {
                      id: currentGroupId,
                    },
                    person: currentPerson,
                    availableRoles: availableRoles,
                    currentRoleIds: currentRoleIds,
                  })
                )
              })
              .catch((err) => {
                console.log(err)
              })
          },
          onApprove: () => {
            return upsert(baseGroupId, currentGroupId, currentRoles)
          },
          onHidden: () => {
            modalComponent.revertUI('#reusable-modal')
          },
        })
        .modal('show')
        .modal('refresh')
    }
  )
}

const onExit = () => {
  $('body').off(
    'click',
    "[data-target='modal-person-new'], [data-target='modal-person-update']"
  )
}

export { setupEvents, onExit }
