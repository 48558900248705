'use strict'

const RSVP = require('es6-promise').Promise
// const Chart               = require('chart.js')

const app = require('./app.es6')
const api = require('./../components/api.es6')
const metadata = require('./../components/metadata.es6')
const roles = require('./../components/roles.es6')
const gravatar = require('./../components/gravatar.es6')
const activityUtils = require('./../components/activity-utils.es6')
const reportsDelete = require('./../components/delete.es6')
const hubpubsub = require('./../../utils/hubpubsub.es6')
const views = require('./../views.es6')

export const showPrototype = (ctx, next) => {
  $(`#workarea`).replaceWith(
    views.render(`prototype/${ctx.params.folder}/${ctx.params.layout}`, {
      contentMaxHeight: $(window).height() - 60,
      gravatar: gravatar,
    })
  )

  $('.ui.dropdown').dropdown({})

  const tbl = $('#datatable').DataTable({
    scrollY: '64vh',
    scrollX: true,
    scrollCollapse: true,
    paging: false,
    language: {
      search: 'Quickfilter:',
      emptyTable: 'No data available',
    },
  })
}
