'use strict'

const RSVP = require('es6-promise').Promise

const api = require('./../components/api.es6')
const hubpubsub = require('./../../utils/hubpubsub.es6')
const views = require('./../views.es6')

const enableTagButtonForTable = (groupId, set, kind) => {
  const tbl = $('.dataTable').DataTable()
  let selectedIds = {}
  let originalTags = []

  $('.btn-tag-js').popup({
    popup: '#reusable-popup.popup',
    preserve: true,
    movePopup: true,
    exclusive: true,
    variation: 'wide',
    position: 'bottom center',
    on: 'click',

    onVisible: () => {
      selectedIds = {
        taskflows: [],
        persons: [],
        groups: [],
        contacts: [],
      }
      originalTags = []
      const selectedRows = tbl.rows('.selected').data()

      if (selectedRows.length == 0) {
        $('#reusable-popup.ui.popup').html(
          views.render('components/tag-items-popup', {})
        )
      } else {
        apiFetchTags(groupId, set).then((tags) => {
          const selectedRowTags = []
          selectedRows.map((r) => {
            const res = r.tags !== undefined ? r.tags.split(', ') : []
            selectedRowTags.push(res)
            if (r.processId !== undefined)
              selectedIds.taskflows.push(r.processId)
            else if (r.DT_RowClass == 'row-person')
              selectedIds.persons.push(parseInt(r.DT_RowId))
            else if (r.DT_RowClass == 'row-group')
              selectedIds.groups.push(parseInt(r.DT_RowId))
            res
          })
          originalTags = _.intersection.apply(_, selectedRowTags)
          $('#reusable-popup.ui.popup').html(
            views.render('components/tag-items-popup', {
              tagList: tags.map((t) => t.label),
              count: selectedRows.length,
              selectedTags: originalTags,
            })
          )

          $('#tag-chooser-dropdown-js').dropdown({
            allowAdditions: true,
          })
          return true
        })
      }

      $('body').on('click', '#reusable-popup .tag-btn-js', (e) => {
        e.preventDefault()
        $('#reusable-popup .tag-btn-js').addClass('loading')

        const activeTags =
          $('#reusable-popup [name="tags"]').val() != ''
            ? $('#reusable-popup [name="tags"]').val().split(',')
            : []
        const toTag = _.difference(activeTags, originalTags)
        const toUntag = _.difference(originalTags, activeTags)

        const promises = []
        if (kind == 'taskflow') {
          promises.push(
            apiTagOrUntag(
              'tag',
              groupId,
              set,
              kind,
              toTag,
              selectedIds.taskflows
            )
          )
          promises.push(
            apiTagOrUntag(
              'untag',
              groupId,
              set,
              kind,
              toUntag,
              selectedIds.taskflows
            )
          )
        } else if (kind == 'people') {
          promises.push(
            apiTagOrUntag(
              'tag',
              groupId,
              set,
              'person',
              toTag,
              selectedIds.persons
            )
          )
          promises.push(
            apiTagOrUntag(
              'untag',
              groupId,
              set,
              'person',
              toUntag,
              selectedIds.persons
            )
          )
          promises.push(
            apiTagOrUntag(
              'tag',
              groupId,
              set,
              'group',
              toTag,
              selectedIds.groups
            )
          )
          promises.push(
            apiTagOrUntag(
              'untag',
              groupId,
              set,
              'group',
              toUntag,
              selectedIds.groups
            )
          )
        }

        RSVP.all(promises)
          .then((tagPosts) => {
            if (kind == 'taskflow') {
              apiFetchTagsLinkedToItemIds(
                groupId,
                set,
                kind,
                selectedIds.taskflows
              ).then((linkedTags) => {
                mergeLinkedTagsIntoTableData(
                  selectedIds.taskflows,
                  linkedTags
                ).then(() => {
                  tbl.rows('.selected').deselect()
                  $('.btn-tag-js').popup('hide')
                })
              })
            } else if (kind == 'people') {
              apiFetchTagsLinkedToItemIds(
                groupId,
                set,
                'person',
                selectedIds.persons
              ).then((linkedPersonTags) => {
                mergeLinkedTagsIntoTableData(
                  selectedIds.persons,
                  linkedPersonTags,
                  '.row-person'
                ).then(() => {
                  apiFetchTagsLinkedToItemIds(
                    groupId,
                    set,
                    'group',
                    selectedIds.groups
                  ).then((linkedGroupTags) => {
                    mergeLinkedTagsIntoTableData(
                      selectedIds.groups,
                      linkedGroupTags,
                      '.row-group'
                    ).then(() => {
                      tbl.rows('.selected').deselect()
                      $('.btn-tag-js').popup('hide')
                    })
                  })
                })
              })
            }
          })
          .catch(function (reason) {})
      })

      $('body').on('click', '#reusable-popup .close-popup', (e) => {
        $('.btn-tag-js').popup('hide')
      })
    },

    onHidden: () => {
      $('body').off('click', '#reusable-popup .tag-btn-js')
      $('body').off('click', '#reusable-popup .close-popup')
      $('#reusable-popup.ui.popup').html(
        views.render('components/empty-popup', {})
      )
    },
  })
}

export const addTagsToTableData = (groupId, config, kind) => {
  return new RSVP.Promise((resolve, reject) => {
    const rowsData = $('#datatable').DataTable().data()
    const itemIds = Array.from(
      rowsData.map((r) => {
        return r.processId
      })
    )
    const set = config !== undefined ? config.set : 'tags'

    apiFetchTagsLinkedToItemIds(groupId, set, kind, itemIds).then(
      (linkedTags) => {
        mergeLinkedTagsIntoTableData(itemIds, linkedTags).then(() => {
          enableTagButtonForTable(groupId, set, kind)
          resolve()
        })
      }
    )
  })
}

export const addTagsToPeopleData = (groupId, set) => {
  return new RSVP.Promise((resolve, reject) => {
    const tbl = $('#datatable').DataTable()
    const personData = tbl.rows('.row-person').ids()
    const groupData = tbl.rows('.row-group').ids()
    const personIds = Array.from(
      personData.map((id) => {
        return parseInt(id)
      })
    )
    const groupIds = Array.from(
      groupData.map((id) => {
        return parseInt(id)
      })
    )

    apiFetchTagsLinkedToItemIds(groupId, set, 'person', personIds).then(
      (linkedPersonTags) => {
        mergeLinkedTagsIntoTableData(
          personIds,
          linkedPersonTags,
          '.row-person'
        ).then(() => {
          apiFetchTagsLinkedToItemIds(groupId, set, 'group', groupIds).then(
            (linkedGroupTags) => {
              mergeLinkedTagsIntoTableData(
                groupIds,
                linkedGroupTags,
                '.row-group'
              ).then(() => {
                enableTagButtonForTable(groupId, set, 'people')
                resolve()
              })
            }
          )
        })
      }
    )
  })
}

export const addTagsToRiskTableData = (groupId, set, kind) => {
  return new RSVP.Promise((resolve, reject) => {
    const rowsData = $('#datatable').DataTable().data()
    const items = rowsData.map((r) => {
      return { combo_id: r.combo_id, statement_id: r.statement_id }
    })

    apiFetchTagsLinkedToItemIds(
      groupId,
      set,
      kind,
      _.pluck(items, 'statement_id')
    ).then((linkedTags) => {
      mergeLinkedTagsIntoRiskTableData(items, linkedTags).then(() => {
        resolve()
      })
    })
  })
}

export const apiFetchTags = (groupId, set, kind) => {
  return new RSVP.Promise((resolve, reject) => {
    let url = `/apiv3/tags/${groupId}/${set}`
    if (kind !== undefined) url += `?linkedto=${kind}`
    api.send(url).then((data) => {
      resolve(data.tags.length > 0 ? data.tags : [])
    })
  })
}

const apiFetchTagsLinkedToItemIds = (groupId, set, kind, itemIds) => {
  return new RSVP.Promise((resolve, reject) => {
    const url = `/apiv3/tags/${groupId}/tagged/${set}/${kind}`
    api.send(url, 'POST', itemIds).then((data) => {
      resolve(data)
    })
  })
}

const mergeLinkedTagsIntoTableData = (itemIds, linkedTags, selector) => {
  return new RSVP.Promise((resolve, reject) => {
    const tbl = $('#datatable').DataTable()
    if (selector === undefined) selector = ''
    const groupedByIds = _.groupBy(linkedTags, (lt) => {
      return lt.itemId
    })

    itemIds.forEach((id) => {
      const rowSelector = `[id^=${id}]${selector}`
      const rowData = tbl.row(rowSelector).data()
      if (groupedByIds[id] !== undefined) {
        const tags = groupedByIds[id].map((t) => {
          return t.label
        })
        rowData.tags = tags.join(', ')
      } else {
        rowData.tags = undefined
      }
      tbl.row(rowSelector).data(rowData)
    })
    tbl.draw('page')
    resolve()
  })
}

const mergeLinkedTagsIntoRiskTableData = (items, linkedTags) => {
  return new RSVP.Promise((resolve, reject) => {
    const tbl = $('#datatable').DataTable()
    const groupedByIds = _.groupBy(linkedTags, (lt) => {
      return lt.itemId
    })
    Array.from(items).forEach((row) => {
      const rowData = tbl.row(`#${row.combo_id}`).data()
      if (groupedByIds[row.statement_id] !== undefined) {
        const tags = groupedByIds[row.statement_id].map((t) => {
          return t.label
        })
        rowData.tags = tags.join(', ')
      } else {
        rowData.tags = undefined
      }
      tbl.row(`#${row.combo_id}`).data(rowData)
    })
    tbl.draw()
    resolve()
  })
}

export const apiTagOrUntag = (
  tagOrUntag,
  groupId,
  set,
  kind,
  tags,
  itemIds
) => {
  return new RSVP.Promise((resolve, reject) => {
    if (tags.length > 0 && itemIds.length > 0) {
      const opts = {
        contentType: 'application/json',
        dataType: 'text',
      }
      const payload = {
        tags: tags,
        itemIds: itemIds,
      }
      api
        .send(
          `/apiv3/tags/${groupId}/${tagOrUntag}/${set}/${kind}`,
          'POST',
          payload,
          opts
        )
        .then((data) => {
          resolve()
        })
    } else {
      resolve()
    }
  })
}

export const formatter = (data, options) => {
  if (data === undefined) data = ''
  else {
    if (options === undefined) options = {}
    data = data
      .split(', ')
      .map((t) => {
        return options[t] !== undefined
          ? `<a class="ui mini ${options[t]} label">${t}</a>`
          : `<a class="ui mini basic label">${t}</a>`
      })
      .join('')
  }
  return data
}
