'use strict'

const RSVP = require('es6-promise').Promise
const page = require('page')
const Ably = require('ably')

const appConfig = require('./../../config.es6').getConfig()
const api = require('./../components/api.es6')
const groupUtils = require('./../components/group-utils.es6')
const metadata = require('./../components/metadata.es6')
const analytics = require('./../../utils/analytics.es6')
const hubpubsub = require('./../../utils/hubpubsub.es6')

import dt from 'datatables.net'
import 'datatables.net-se/js/dataTables.semanticui.js'
import 'datatables.net-select/js/dataTables.select.js'
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-plugins/sorting/natural.js'

export let groupId, groupName, groupLevel
export let isOwner,
  isUserAdmin,
  isMaker,
  isContactAdmin,
  isMakerAccount,
  isApplication,
  isOrganization
export let checkForAppUpdatesId
export const realtimePubSub = new Ably.Realtime(window.ably_token)

let parentGroupTree = []
let childGroupTree = []
let permissions = []
let appUpdateAvailable = false
let isOffline = false

export const startup = () => {
  return new RSVP.Promise((resolve, reject) => {
    if (isLoggedIn()) {
      getLastGroupId()
        .then(() => {
          analytics.initAmplitude()
          checkForAppUpdatesId = setInterval(
            checkForAppUpdates,
            appConfig.checkForAppUpdatesDelay
          )

          if (
            window.location.href.match(/\${groupId}/g) ||
            window.location.href.match(/\$%7BgroupId%7D/g)
          ) {
            window.location.href = window.location.href
              .replace(/\%7BgroupId%7D/g, '{groupId}')
              .replace(/\${groupId}/g, groupId)
            window.location.reload(true)
          }

          resolve()
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      indexedDB.deleteDatabase('KotiveDB')
      localStorage.clear()

      localStorage.setItem('nextCtx', window.location.hash.substr(2))

      window.location.href = '/hub/login'
      reject('You are not logged-in.')
    }
  })
}

export const refresh = (ctx, next) => {
  document.title = 'Kotive'
  if (appUpdateAvailable) {
    window.location.href = `#!${ctx.path}`
    fetchAppUpdates()
  } else if (isLoggedIn()) {
    groupId =
      ctx.params.groupId !== undefined
        ? ctx.params.groupId
        : localStorage.getItem('company-group')
    const options = {
      isOwner: isOwner,
      isUserAdmin: isUserAdmin,
      isMaker: isMaker,
      isContactAdmin: isContactAdmin,
    }
    $('#navigation-menu a.item').removeClass('active')
    if (ctx.path.length > 2)
      $(`#navigation-menu a.item[href*='${ctx.path}']`).addClass('active')
    next()
  } else {
    window.location.href = '/hub/login'
  }
}

export const isLoggedIn = () => {
  return (
    localStorage.getItem('user-email') != null &&
    localStorage.getItem('auth-token') != null
  )
}

const getLastGroupId = () => {
  return new RSVP.Promise((resolve, reject) => {
    const companyGroup = localStorage.getItem('company-group')
    if (companyGroup !== undefined && companyGroup !== null) {
      if (companyGroup.match(/^[0-9]*$/)) {
        const userGroups = JSON.parse(localStorage.getItem('user-groups'))
        const userIsLinkedToGroup = _.find(userGroups, (group) => {
          return group.id == companyGroup
        })
        if (userIsLinkedToGroup !== undefined) {
          groupId = companyGroup
          resolve()
        } else {
          page('/switch')
          reject(
            'You are not linked to any apps, organizations or teams. Choose one, if possible.'
          )
        }
      } else {
        api.send(`/apiv3/keychain/groups/${companyGroup}`).then((data) => {
          groupId = data.serverId
          metadata.updateUserMetadata([
            {
              key: 'company-group',
              value: groupId.toString(),
              valueType: 'integer',
            },
          ])
          localStorage.setItem('company-group', groupId)
          resolve()
        })
      }
    } else {
      page('/switch')
      reject(
        'You are not linked to any apps, organizations or teams. Choose one, if possible.'
      )
    }
  })
}

export const setupUserAndGroupConfigs = () => {
  return new RSVP.Promise((resolve, reject) => {
    hasSystemRole(1)
      .then((res1) => {
        isOwner = res1
        return hasSystemRole(2)
      })
      .then((res2) => {
        isUserAdmin = res2
        return hasSystemRole(3)
      })
      .then((res3) => {
        isMaker = res3
        return hasSystemRole(4)
      })
      .then((res4) => {
        isContactAdmin = res4
        return isGroupLevel('company')
      })
      .then((resCompany) => {
        isMakerAccount = resCompany
        return isGroupLevel('application')
      })
      .then((resApplication) => {
        isApplication = resApplication
        return isGroupLevel('organization')
      })
      .then((resOrganization) => {
        isOrganization = resOrganization
        resolve()
      })
  })
}

const hasSystemRole = (role) => {
  return new RSVP.Promise((resolve, reject) => {
    const roles = JSON.parse(localStorage.getItem('user-roles'))
    const found = _.find(roles, (r) => {
      return parentGroupTree.indexOf(r.groupId) !== -1 && r.role.id == role
    })
    resolve(found !== undefined ? true : false)
  })
}

export const activeRolesInGroup = () => {
  const roles = JSON.parse(localStorage.getItem('user-roles'))
  const rolesInGroup = _.filter(roles, (r) => {
    return parentGroupTree.includes(r.groupId)
  })
  return rolesInGroup
}

const isGroupLevel = (level) => {
  return new RSVP.Promise((resolve, reject) => {
    groupUtils.getAllGroupsForUser().then((groups) => {
      const found = _.find(groups, (g) => {
        return g.id == groupId && g.level == level
      })
      if (found !== undefined) {
        groupName = found.label
        groupLevel = found.level
        resolve(true)
      } else {
        resolve(false)
      }
    })
  })
}

export const buildParentGroupTree = () => {
  parentGroupTree = []
  getParentGroup(groupId)
  return parentGroupTree
}

const getParentGroup = (id) => {
  const groups = JSON.parse(localStorage.getItem('user-groups'))
  const current = _.find(groups, (g) => {
    return g.id == id
  })
  if (current != undefined) {
    parentGroupTree.push(current.id)
    getParentGroup(current.parentId)
  }
}

export const switchAccount = (id) => {
  return new RSVP.Promise((resolve, reject) => {
    localStorage.setItem('company-group', id)
    groupId = id
    metadata.updateUserMetadata([
      { key: 'company-group', value: groupId.toString(), valueType: 'integer' },
    ])
    if (appUpdateAvailable) {
      fetchAppUpdates()
      reject('Updated version of app is available. Refreshing browser.')
    } else {
      resolve()
    }
  })
}

export const checkForAppUpdates = () => {
  $.ajax({
    url: '/hub/assets/manifest.json',
    method: 'HEAD',
    contentType: 'application/json; charset=utf-8',
    dataType: 'json',
    cache: false,
  })
    .done((data, textStatus, jqxhr) => {
      const storedLastModified = localStorage.getItem('app-last-modified')
      const lastModified = jqxhr.getResponseHeader('last-modified')
      isOffline = false

      if (storedLastModified == null) {
        localStorage.setItem('app-last-modified', lastModified)
      } else if (localStorage.getItem('app-last-modified') !== lastModified) {
        appUpdateAvailable = true
      }
    })
    .fail((jqXHR, textStatus, errorThrown) => {
      isOffline = true
      console.error(
        `Failed to check for updates... ${jqXHR.status}... (${textStatus}`
      )
    })
}

export const fetchAppUpdates = () => {
  localStorage.removeItem('app-last-modified')
  $('#reusable-notifications').empty()
  window.location.reload(true)
}

export const logout = (ctx, next) => {
  api.send(`/apiv3/logout`).then((data) => {
    indexedDB.deleteDatabase('KotiveDB')
    localStorage.clear()
    analytics.logout()
    window.location.href = '/hub/login'
  })
}

export const setupEvents = () => {
  $('body').on('click', '.copyThisHref', (e) => {
    e.preventDefault()
    navigator.clipboard.writeText(e.target.href)
  })
}
