'use strict'

const RSVP = require('es6-promise').Promise
const page = require('page')

const app = require('./app.es6')
const api = require('./../components/api.es6')
const groupAddUpdate = require('./../components/group-add-update.es6')
const metadata = require('./../components/metadata.es6')
const roles = require('./../components/roles.es6')
const gravatar = require('./../components/gravatar.es6')
const activityUtils = require('./../components/activity-utils.es6')
const reportsDelete = require('./../components/delete.es6')
const todos = require('./../components/todos.es6')
const widgets = require('./../components/widgets.es6')
const analytics = require('./../../utils/analytics.es6')
const hubpubsub = require('./../../utils/hubpubsub.es6')
const views = require('./../views.es6')

let currentGroupId, baseGroupId, currentGroup, groupStructure

const init = (ctx, next) => {
  baseGroupId = ctx.params.baseGroupId
  currentGroupId =
    ctx.params.currentGroupId !== undefined
      ? ctx.params.currentGroupId
      : ctx.params.baseGroupId
  currentGroup = {}

  setupEvents()

  showPage()
    .then(() => {
      const trackingMetadata = { hubUrl: window.location.href }
      analytics.trackEvent('Viewed organization profile', trackingMetadata)
    })
    .catch((err) => {
      console.log(err)
    })
}

const showPage = () => {
  return new RSVP.Promise((resolve, reject) => {
    renderCanvas()
      .then(() => {
        return renderOrganization()
      })
      .then((group) => {
        currentGroup = group
        todos.showCardsForOrganisation(currentGroupId)
        return renderBreadcrumb()
      })
      .then(() => {
        groupAddUpdate.setupEvents(baseGroupId, currentGroupId, groupStructure)
        $('.organization-update-js').removeClass('hidden')
        renderFeed()
        const findByRegexes = [/group-profile-[0-9]/g]
        return metadata.refreshUserMetadata(app.groupId, findByRegexes)
      })
      .then((groupMetadata) => {
        return renderProfile(groupMetadata)
      })
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const renderCanvas = () => {
  return new RSVP.Promise((resolve, reject) => {
    $('#workarea').replaceWith(
      views.render('people/organization', {
        baseGroupId: baseGroupId,
        currentGroupId: currentGroupId,
      })
    )
    resolve()
  })
}

const renderOrganization = () => {
  return new RSVP.Promise((resolve, reject) => {
    api.send(`/apiv3/group/${currentGroupId}`).then((group) => {
      if (group.group) {
        $('#organization-badge').replaceWith(
          views.render('people/organization-badge', {
            group: group.group,
          })
        )
        document.title = `${group.group.label} | Kotive`
        resolve(group.group)
      } else {
        reject("Group's id is invalid.")
      }
    })
  })
}

const renderProfile = (groupMetadata) => {
  return new RSVP.Promise((resolve, reject) => {
    $('#organization-sheet').empty()
    // $('#organization-quicklinks').show()

    const promises = groupMetadata
      .filter((gm) => {
        return (
          gm.layout == 'widget' ||
          (app.permissions &&
            app.permissions[gm.templateActivityId] &&
            app.permissions[gm.templateActivityId].hasAccess)
        )
      })
      .map((metadataEntry) => {
        if (metadataEntry.layout == 'widget') {
          metadataEntry.divId = `${metadataEntry.label.split(' ').join('-')}`
          $('#organization-sheet').append(
            views.render('widgets/card', {
              id: metadataEntry.divId,
              label: metadataEntry.label,
            })
          )
          metadataEntry.data = JSON.stringify(metadataEntry.data)
          metadataEntry.data = metadataEntry.data.replace(
            /\${groupId}/g,
            currentGroupId
          )
          metadataEntry.url = metadataEntry.url.replace(
            /\${groupId}/g,
            currentGroupId
          )
          return widgets.renderExternalWidget(metadataEntry)
        } else {
          $('#organization-sheet').append(
            views.render('profiles/profile-component', {
              taskflowId: `${metadataEntry.templateActivityId}`,
              label: `${metadataEntry.label}`,
              layout: `${metadataEntry.layout}`,
            })
          )
          $('#organization-quicklinks').append(
            views.render('people/quicklinks', {
              label: `${metadataEntry.label}`,
              link: `${metadataEntry.templateActivityId}`,
            })
          )

          return renderProfileComponent(metadataEntry)
        }
      })

    RSVP.all(promises)
      .then((components) => {
        $('table.dataTable').DataTable({
          searching: false,
          paging: false,
          info: false,
          scrollY: '64vh',
          scrollX: true,
          scrollCollapse: true,
          select: {
            style: 'multi',
            selector: 'td:first-child',
            info: false,
          },
          language: {
            search: 'Quickfilter:',
            emptyTable:
              '<span class="yellow-strip">No information is available...</span>',
          },
        })
        resolve()
      })
      .catch((error) => {
        console.error(error)
        hubpubsub.publish('notification.show', {
          kind: 'negative',
          message:
            'Sorry, something went wrong... Please refresh your browser and try again.',
        })
        reject(error)
      })
  })
}

const renderProfileComponent = (component) => {
  return new RSVP.Promise((resolve, reject) => {
    const query = {
      templateActivityIds: metadata.getPropertyFromColumns(
        component.columns,
        'templateActivityId'
      ),
      templateFieldIds: metadata.getPropertyFromColumns(
        component.columns,
        'templateFieldId'
      ),
    }
    // const fromTimestamp = localStorage.getItem('date-range-start')
    // if (fromTimestamp !== null) query.fromTimestamp = fromTimestamp
    query.fromTimestamp = 0
    // const toTimestamp   = localStorage.getItem('date-range-end')
    // if (toTimestamp !== null) query.toTimestamp = toTimestamp
    query.size = component.layout == 'table' ? 15 : 1

    if (component.linkedPermissionFields !== undefined)
      query.linkedPermissionFields = component.linkedPermissionFields
    query.dynamicLookups = true
    query.restrictToGroup = true

    api
      .send(
        `/apiv3/reports/${currentGroupId}/${component.templateActivityId}`,
        'POST',
        query
      )
      .then((activities) => {
        activityUtils
          .prepareActivities(
            currentGroupId,
            component.templateActivityId,
            activities,
            component.columns,
            component.componentActions
          )
          .then(([preppedActivities, latestTS]) => {
            activityUtils
              .reorderFieldsInActivities(component.columns, preppedActivities)
              .then((reorderedActivities) => {
                if (!app.permissions[component.templateActivityId].start)
                  delete component.componentActions['add']
                if (!app.permissions[component.templateActivityId].continue)
                  delete component.componentActions['continue']
                if (!app.permissions[component.templateActivityId].delete)
                  delete component.componentActions['delete']

                $(`#profile-${component.templateActivityId}`).replaceWith(
                  views.render('profiles/profile-component', {
                    groupId: currentGroupId,
                    taskflowId: component.templateActivityId,
                    component: component,
                    activities: reorderedActivities,
                  })
                )

                if (app.permissions[component.templateActivityId].delete)
                  reportsDelete.enableDeleteButton(
                    'activities',
                    currentGroupId,
                    component.templateActivityId
                  )

                resolve(preppedActivities)
              })
          })
      })
  })
}

const renderBreadcrumb = () => {
  return new RSVP.Promise((resolve, reject) => {
    api
      .send(`/apiv3/group/${baseGroupId}/groupStructure/${currentGroupId}`)
      .then((groups) => {
        groupStructure = groups.groups
        $(`#breadcrumb`).replaceWith(
          views.render('people/people-breadcrumb', {
            baseGroupId: baseGroupId,
            groups: groupStructure.reverse(),
          })
        )
        resolve()
      })
  })
}

const renderFeed = () => {
  return new RSVP.Promise((resolve, reject) => {
    // $(`#feed-timeline`).replaceWith(views.render("feed-timeline", {}))
    resolve()
  })
}

const refreshBadge = (group) => {
  return new RSVP.Promise((resolve, reject) => {
    currentGroup = group
    $('#organization-badge').replaceWith(
      views.render('people/organization-badge', {
        group: group,
      })
    )
    renderBreadcrumb().then(() => {
      groupAddUpdate.setupEvents(baseGroupId, currentGroupId, groupStructure)
      $('.organization-update-js').removeClass('hidden')
      renderFeed()
      resolve()
    })
  })
}

const setupEvents = () => {
  const refreshOrganizationBadgeSubscription = hubpubsub.subscribe(
    'organization.refreshBadge',
    (group) => {
      $('#organization-badge').html(
        `<div class="content text-center"><img class="ui centered image" src="${require('../../../images/loading.gif')}"/></div>`
      )
      refreshBadge(group)
    }
  )
  $('body').on('click', '#todo-byduedate-btn-js', (e) => {
    e.preventDefault()
    $('#todo-byduedate-btn-js').addClass('loading')
    localStorage.setItem('group-todos-mode', 'groupByDueDate')
    todos.showCardsForOrganisation(currentGroupId)
  })
  $('body').on('click', '#todo-bypeople-btn-js', (e) => {
    e.preventDefault()
    $('#todo-bypeople-btn-js').addClass('loading')
    localStorage.setItem('group-todos-mode', 'groupByPerson')
    todos.showCardsForOrganisation(currentGroupId)
  })
}

const onPageExit = (ctx, next) => {
  $('#reusable-popup.popup').detach().appendTo('body')
  $('body').off('click', '#todo-byduedate-btn-js')
  $('body').off('click', '#todo-bypeople-btn-js')
  groupAddUpdate.onExit()
  next()
}

export { init, setupEvents, onPageExit }
