'use strict'

const RSVP = require('es6-promise').Promise
const dayjs = require('dayjs')

const app = require('./../modules/app.es6')
const api = require('./../components/api.es6')
const todos = require('./../components/todos.es6')
const todosUtil = require('./../../utils/todos.es6')
const views = require('./../views.es6')

let personId = 'me',
  realtimeChannel
let disableLinks = true
let todosMode
let todosMetadata

export const showCardForPerson = (id) => {
  return new RSVP.Promise((resolve, reject) => {
    todosMode = 'personByDueDate'
    personId = id == 'me' ? localStorage.getItem('user-id') : id
    disableLinks = personId === localStorage.getItem('user-id') ? false : true
    todosMetadata =
      localStorage.getItem(`todos-${app.groupId}`) !== null
        ? JSON.parse(localStorage.getItem(`todos-${app.groupId}`))
        : {}

    renderCard()
      .then(() => {
        return apiFetchTodos(personId)
      })
      .then((todos) => {
        return formatTodos(todos)
      })
      .then(([formattedTodosByDueDate, formatTodosByPerson]) => {
        return renderTodos(formattedTodosByDueDate, 'personByDueDate')
      })
      .then(() => {
        todosUtil.initInHub(app.groupId, '#todos-card')
        return subscribeToRealtimeChannel()
      })
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const showCardsForOrganisation = (groupId) => {
  return new RSVP.Promise((resolve, reject) => {
    personId = parseInt(localStorage.getItem('user-id'))
    todosMode =
      localStorage.getItem('group-todos-mode') != null
        ? localStorage.getItem('group-todos-mode')
        : 'groupByDueDate'
    todosMetadata =
      localStorage.getItem(`todos-${app.groupId}`) !== null
        ? JSON.parse(localStorage.getItem(`todos-${app.groupId}`))
        : {}

    renderCard()
      .then(() => {
        return apiFetchAllTodos(groupId)
      })
      .then((todos) => {
        return formatTodos(todos)
      })
      .then(([formattedTodosByDueDate, formatTodosByPerson]) => {
        if (todosMode == 'groupByPerson') {
          return renderTodos(formatTodosByPerson, todosMode)
        } else {
          return renderTodos(formattedTodosByDueDate, todosMode)
        }
      })
      .then(() => {
        todosUtil.initInHub(groupId, '#todos-card')
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const renderCard = () => {
  return new RSVP.Promise((resolve, reject) => {
    $('#todos-card').replaceWith(
      views.render('todos/card', {
        loading: true,
      })
    )
    resolve()
  })
}

const apiFetchTodos = (userId) => {
  return new RSVP.Promise((resolve, reject) => {
    api.send(`/apiv3/todos/${app.groupId}/${userId}`).then((data) => {
      resolve(data.todos)
    })
  })
}

const apiFetchAllTodos = (groupId) => {
  return new RSVP.Promise((resolve, reject) => {
    api.send(`/apiv3/todos/${app.groupId}`).then((data) => {
      resolve(data.todos)
    })
  })
}

const formatTodos = (todos) => {
  return new RSVP.Promise((resolve, reject) => {
    const now = dayjs().valueOf()
    const formattedTodosByDueDate = {}
    const todosByPerson = {}
    const formattedTodosByPerson = {}

    _.each(todos, (todo) => {
      if (todo.properties !== undefined && todo.properties !== null) {
        if (todosMode !== 'personByDueDate')
          disableLinks =
            todo.assignedTo !== undefined &&
            todo.assignedTo.includes(parseInt(personId))
              ? false
              : true
        todo.properties = JSON.parse(todo.properties)
        if (todo.properties.target === undefined) todo.properties.target = ''
        if (todo.properties.autoclose === undefined)
          todo.properties.autoclose = false
        if (
          disableLinks ||
          todo.status == 'complete' ||
          todo.status == 'expired'
        ) {
          todo.isDisabled = true
          delete todo.properties
        }
      }

      if (todo.dueAt !== undefined && todo.dueAt != '')
        todo.dueAtFormatted = dayjs(todo.dueAt).format('D MMM YYYY')
      if (
        todo.status == 'expired' ||
        (todo.expireAt < now &&
          (todo.status == 'complete' || todo.status == 'overdue'))
      )
        todo.isDisabled = true

      if (todo.status == 'incomplete' || todo.status == 'upcoming')
        todo.icon = 'grey circle outline'
      else if (todo.status == 'complete') todo.icon = 'green check circle'
      else if (todo.status == 'overdue') todo.icon = 'orange circle outline'
      else if (todo.status == 'expired') todo.icon = 'circle outline'

      let bucketDueDate = ''
      if (todo.status == 'overdue' || todo.status == 'expired')
        bucketDueDate = 'overdue'
      else if (todo.status == 'complete') bucketDueDate = 'complete'
      else if (todo.dueAt !== undefined)
        bucketDueDate = dayjs(todo.dueAt).format('ddd, D MMM YYYY')
      else bucketDueDate = 'withoutDueDate'

      if (formattedTodosByDueDate[bucketDueDate] === undefined)
        formattedTodosByDueDate[bucketDueDate] = []
      formattedTodosByDueDate[bucketDueDate].push(todo)

      todo.assignedTo.forEach((assignedToId) => {
        if (todosByPerson[assignedToId] === undefined)
          todosByPerson[assignedToId] = {}
        if (todosByPerson[assignedToId][bucketDueDate] === undefined)
          todosByPerson[assignedToId][bucketDueDate] = []
        todosByPerson[assignedToId][bucketDueDate].push(todo)
      })
    })

    if (formattedTodosByDueDate['withoutDueDate'] !== undefined) {
      const withoutDueDateTodos = formattedTodosByDueDate['withoutDueDate']
      delete formattedTodosByDueDate['withoutDueDate']
      formattedTodosByDueDate.withoutDueDate = withoutDueDateTodos
    }

    if (formattedTodosByDueDate['complete'] !== undefined) {
      const completedTodos = formattedTodosByDueDate['complete']
      delete formattedTodosByDueDate['complete']
      formattedTodosByDueDate.complete = completedTodos.reverse()
    }

    if (formattedTodosByDueDate['overdue'] !== undefined)
      formattedTodosByDueDate['overdue'] =
        formattedTodosByDueDate['overdue'].reverse()

    Object.keys(todosByPerson).forEach((personId) => {
      if (todosByPerson[personId]['withoutDueDate'] !== undefined) {
        const withoutDueDateTodos = todosByPerson[personId]['withoutDueDate']
        delete todosByPerson[personId]['withoutDueDate']
        todosByPerson[personId].withoutDueDate = withoutDueDateTodos
      }

      if (todosByPerson[personId]['complete'] !== undefined) {
        const completedTodos = todosByPerson[personId]['complete']
        delete todosByPerson[personId]['complete']
        todosByPerson[personId].complete = completedTodos.reverse()
      }

      if (todosByPerson[personId]['overdue'] !== undefined)
        todosByPerson[personId]['overdue'] =
          todosByPerson[personId]['overdue'].reverse()

      formattedTodosByPerson[personId] = todosByPerson[personId]
    })

    resolve([formattedTodosByDueDate, todosByPerson])
  })
}

const renderTodos = (todos, renderMode = 'personByDueDate') => {
  return new RSVP.Promise((resolve, reject) => {
    const hasTodos = Object.keys(todos).length > 0 ? true : false
    let title =
      personId == localStorage.getItem('user-id')
        ? 'Your upcoming to-dos'
        : 'Assigned to-dos'
    if (renderMode == 'groupByPerson') title = 'To-dos by person'
    else if (renderMode == 'groupByDueDate') title = 'To-dos by date'
    $('#todos-card').replaceWith(
      views.render('todos/card', {
        groupId: app.groupId,
        title: title,
        todos: todos,
        hasTodos: hasTodos,
        renderMode: renderMode,
        todosMetadata: todosMetadata,
      })
    )
    resolve()
  })
}

const subscribeToRealtimeChannel = () => {
  return new RSVP.Promise((resolve, reject) => {
    let isBusy = false
    realtimeChannel = app.realtimePubSub.channels.get(
      `todos:${app.groupId}:${personId}`
    )

    realtimeChannel.subscribe(
      ['started', 'continued', 'updated'],
      (message) => {
        if (!isBusy) {
          isBusy = true
          apiFetchTodos(personId)
            .then((todos) => {
              return formatTodos(todos)
            })
            .then(([formattedTodosByDueDate, formatTodosByPerson]) => {
              return renderTodos(formattedTodosByDueDate, 'personByDueDate')
            })
            .then(() => {
              todosUtil.initInHub(app.groupId, '#todos-card')
              isBusy = false
              resolve()
            })
        }
      }
    )
    resolve()
  })
}

export const unsubscribeFromRealtimeChannel = () => {
  return new RSVP.Promise((resolve, reject) => {
    realtimeChannel.unsubscribe(['started', 'continued'])
    resolve()
  })
}
