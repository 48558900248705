"use strict";

const serialize = require("form-serialize");
const RSVP = require("es6-promise").Promise;

const app = require("./app.es6");
const api = require("./../components/api.es6");
const hubpubsub = require("./../../utils/hubpubsub.es6");
const modalComponent = require("./../../utils/modal.es6");
const analytics = require("./../../utils/analytics.es6");
const files = require("./../../utils/files.es6");
const tasks = require("./../../utils/tasks.es6");

let activeModals = [];
let hasSavedAtLeastOnce = false;

export const setupEvents = () => {
  // launch multiple modals for taskflows
  $("body").on("click", "[data-target='modal-taskflow'],[data-target='modal-taskflow-batch'],[data-target='blank-taskflow']", (e, opts) => {
    e.preventDefault();

    const dataOpts = opts !== undefined ? opts : tasks.discoverDataOptions(e.target, 0);

    if (dataOpts.target == "blank-taskflow") {
      window.open(dataOpts.url, "_blank");
    } else {
      const queryStr = tasks.buildQueryString(dataOpts.params, activeModals, dataOpts.mode);
      const targetUrl = dataOpts.url.indexOf("?") !== -1 ? `${dataOpts.url}&${queryStr}` : `${dataOpts.url}?${queryStr}`;
      const indexOfQuerySplit = dataOpts.url.indexOf("?") !== -1 ? dataOpts.url.indexOf("?") : dataOpts.url.length;
      const urlInParts = dataOpts.url.substr(0, indexOfQuerySplit).split("/");
      const reqType = urlInParts[6];
      const embedded = urlInParts[7] == null ? false : true;
      const uniqueId = new Date().getTime();
      dataOpts.uniqueId = uniqueId;
      activeModals.push(dataOpts);

      const thisModal = $("#reusable-modal").clone().attr("id", uniqueId).appendTo("body");
      $(thisModal).data("dataOpts", dataOpts);

      modalComponent.resizeHeight(`#${uniqueId}`);

      $(`#${uniqueId}`)
        .modal({
          duration: 10,
          allowMultiple: true,
          selector: {
            deny: ".close-modal, .actions .close-modal",
            approve: ".actions .positive, .actions .approve, .actions .ok",
          },

          onVisible: () => {
            const pixelOffset = 10;
            const marginTop = parseInt($(`#${uniqueId}`).css("margin-top")) + (activeModals.length - 1) * pixelOffset;
            const marginLeft = parseInt($(`#${uniqueId}`).css("margin-left")) + (activeModals.length - 1) * pixelOffset;
            $(`#${uniqueId}`).css("margin-top", `${marginTop}px`);
            $(`#${uniqueId}`).css("margin-left", `${marginLeft}px`);

            $.ajax({
              url: targetUrl,
              method: "POST",
              contentType: "application/json; charset=utf-8",
              beforeSend: (xhr) => {
                const authHash = btoa(localStorage.getItem("user-email") + ":" + localStorage.getItem("auth-token"));
                xhr.setRequestHeader("Authorization", "Basic " + authHash);
              },
            })
              .done((data, textStatus, jqXHR) => {
                tasks.refreshContent(`#${uniqueId}`, reqType, data);
                tasks.startup(app.groupId, reqType, embedded, `#${uniqueId}`, dataOpts.mode);

                if (dataOpts.mode === undefined) {
                  analytics.trackEvent("Viewed a live taskflow");
                } else if (dataOpts.mode == "batch") {
                  tasks.observeChangedFieldsInForm(`#${uniqueId}`);
                  const recordCount = $("#datatable tbody tr.selected").length;
                  $(`#${uniqueId} .header`).html(`Smart batch update ${recordCount} records`);
                  analytics.trackEvent("Viewed a batch update");
                }
              })
              .fail((jqXHR, textStatus, errorThrown) => {
                tasks.hideModalControls(`#${uniqueId}`);
                tasks.refreshContent(
                  `#${uniqueId}`,
                  reqType,
                  `<p class="text-center">Something went wrong.<br/>Please try again or contact support.<br/>${jqXHR.statusText} (${jqXHR.status})</p>`
                );
              });
          },

          onApprove: () => {
            tasks.disableModalControls(`#${uniqueId}`);

            if (dataOpts.mode === undefined) {
              saveTaskflow(uniqueId, reqType, dataOpts);
              return false;
            } else if (dataOpts.mode == "batch") {
              saveBatch(uniqueId, reqType, dataOpts);
              return false;
            }
          },
          onHidden: () => {
            hasSavedAtLeastOnce = tasks.updateReferrerReport(dataOpts, hasSavedAtLeastOnce);
            activeModals.pop();
            $(`#${uniqueId}`).remove();
          },
        })
        .modal("show")
        .modal("refresh");
    }
  });
};

const saveTaskflow = (uniqueId, reqType, dataOpts) => {
  const authHash = btoa(localStorage.getItem("user-email") + ":" + localStorage.getItem("auth-token"));
  const forms = $(`#${uniqueId} .content .kotive-published-task-form`).toArray();

  const promises = forms.map((form) => {
    return $.ajax({
      url: form.action,
      method: "POST",
      beforeSend: (xhr) => {
        xhr.setRequestHeader("Authorization", "Basic " + authHash);
      },
      data: serialize(form, { empty: true, hash: false }),
    });
  });

  RSVP.all(promises)
    .then((forms) => {
      hasSavedAtLeastOnce = true;

      // Clear saved timer data after successful submission
      forms.forEach((form) => {
        const formId = $(form).find("form").attr("id");
        if (formId) {
          // Import timer-field module dynamically to clear saved data
          import("./../../utils/timer-field.es6")
            .then((timerField) => {
              if (timerField && typeof timerField.clearSavedFormData === "function") {
                timerField.clearSavedFormData(formId);
              }
            })
            .catch((err) => {
              console.error("Error importing timer-field module:", err);
            });
        }
      });

      tasks.refreshContent(`#${uniqueId}`, reqType, forms.pop());
      analytics.trackEvent("Saved a live taskflow");

      setTimeout(() => {
        tasks.updateReferrerModal(dataOpts);
        if (dataOpts.autoclose) {
          $("#datatable tbody tr.selected").removeClass("selected").addClass("processing");
          $(`#${uniqueId} .actions .button.close`).trigger("click");
        } else {
          if ($(`#${uniqueId} .content`).find("form").length === 0) {
            tasks.enableModalCloseControlOnly(`#${uniqueId}`);
          } else {
            tasks.startupInModal(`#${uniqueId}`);
            $(`#${uniqueId} .content`).scrollTop(0);
          }
        }
      }, 100);
    })
    .catch((err) => {
      switch (err.status) {
        case 402:
          analytics.trackEvent("Credits depleted");
          hubpubsub.publish("notification.show", {
            kind: "negative",
            message:
              "Credits depleted. Sorry, you cannot submit the form at this time because the account's credits have been depleted. The account manager has been notified. Please try again later.",
          });
          break;
        default:
          console.log(`Error: [${err.status}] (${err.statusText})`);
          hubpubsub.publish("notification.show", {
            kind: "negative",
            message: "Sorry, something went wrong when trying to submit your form, please try again in a few minutes.",
          });
      }
    });
};

const saveBatch = (uniqueId, reqType, dataOpts) => {
  const authHash = btoa(localStorage.getItem("user-email") + ":" + localStorage.getItem("auth-token"));
  const form = $(`#${uniqueId} .content .kotive-published-task-form`)[0];
  const formFields = serialize(form, { empty: false, hash: true });
  const taskflowId = $(form).data("taskflow-id");
  const taskId = $(form).data("form-id");
  const nudgedFields = $(form).find(".kotive-field-input-nudge");
  const fields = {};
  const processesToStart = {};
  const processesToContinue = {};

  Object.keys(formFields).forEach((f) => {
    if ($(`[id^=${f}]`).closest(".kotive-field-input").hasClass("kotive-field-input-nudge")) {
      fields[f.substring(13)] =
        typeof formFields[f] === "object" || typeof formFields[f] === "array" ? Object.keys(formFields[f]).join("^") : formFields[f];
    }
  });

  if (Object.keys(fields).length == 0) {
    $(`#${uniqueId} .actions .button.close`).trigger("click");
    return false;
  }

  const tbl = $("#datatable").DataTable();
  const selectedRows = $("#datatable tbody tr.selected");

  selectedRows.each((r, row) => {
    const rowData = tbl.row(row).data();
    const groupId = rowData.processId === undefined ? app.groupId.toString() : rowData.groupId.toString();

    if (rowData.processId === undefined) {
      if (processesToStart[groupId] === undefined) processesToStart[groupId] = [];

      const startJson = {
        id: -1,
        id_process: 0,
        id_owning_process: -1,
        id_t: -1,
        fields: [],
      };
      Object.keys(fields).forEach((f) => {
        startJson.fields.push({ id: f, value: fields[f] });
      });
      Object.keys(dataOpts.fields).forEach((id) => {
        const DOMFieldId = $(`[name^='kotive-field-'][name*='${id}']`).attr("id");
        if (DOMFieldId !== undefined) {
          let val = dataOpts.fields[id];
          if (rowData.statement_id !== undefined) val = val.replace(/^#statement_id#/, rowData.statement_id);
          startJson.fields.push({ id: DOMFieldId.substring(13), value: val });
        }
      });
      processesToStart[groupId].push({
        uri: `/group/${groupId}/processes/${taskflowId}?next=none`,
        payload: [startJson],
      });
    } else {
      // update
      if (processesToContinue[groupId] === undefined) processesToContinue[groupId] = [];

      const continueJson = {
        id: -1,
        id_process: rowData.processId,
        id_owning_process: -1,
        id_t: -1,
        fields: [],
      };
      Object.keys(fields).forEach((f) => {
        continueJson.fields.push({ id: f, value: fields[f] });
      });
      processesToContinue[groupId].push({
        uri: `/group/${groupId}/processes/${taskflowId}/continue/${taskId}?next=none`,
        payload: [continueJson],
      });
    }
  });

  const opts = {
    contentType: "application/json",
    dataType: "text",
  };

  Object.keys(processesToStart).forEach((p) => {
    api.send(`/hub/q/add/processes`, "POST", processesToStart[p], opts).then((data) => {});
  });

  Object.keys(processesToContinue).forEach((p) => {
    api.send(`/hub/q/add/processes`, "POST", processesToContinue[p], opts).then((data) => {});
  });

  analytics.trackEvent("Saved a batch update");
  hubpubsub.publish("notification.show", {
    kind: "green",
    message: "Your batch update is being processed... It may take a couple of minutes before the information is refreshed.",
  });

  setTimeout(() => {
    $("#datatable tbody tr.selected").removeClass("selected").addClass("processing");
    $(`#${uniqueId} .actions .button.close`).trigger("click");
  }, 1000);
};
