'use strict'

const RSVP = require('es6-promise').Promise

const api = require('./../components/api.es6')

let groups = []

export const getAllGroupsForUser = () => {
  return new RSVP.Promise((resolve, reject) => {
    if (groups.length == 0) {
      apiFetchAllGroupsForUser().then((grps) => {
        resolve(grps)
      })
    } else {
      resolve(groups)
    }
  })
}

export const apiFetchAllGroupsForUser = () => {
  return new RSVP.Promise((resolve, reject) => {
    api.send(`/apiv3/groups`).then((data) => {
      const uniqueGroups = _.uniq(data.groups, (item, key, id) => {
        return item.id
      })
      groups = _.sortBy(uniqueGroups, () => {
        return 'id'
      })
      resolve(groups)
    })
  })
}
