'use strict'

const page = require('page')

require('../components/semantic/dist/components/popup')
require('../components/semantic/dist/components/transition')
require('../components/semantic/dist/components/modal')
require('../components/semantic/dist/components/dimmer')
require('../components/semantic/dist/components/tab')
require('../components/semantic/dist/components/dropdown')
require('../components/semantic/dist/components/progress')
require('../components/semantic/dist/components/calendar')

const appConfig = require('./config.es6').getConfig()
const app = require('./hub/modules/app.es6')
const reports = require('./hub/modules/reports.es6')
const dashboard = require('./hub/modules/dashboard.es6')
const taskflows = require('./hub/modules/taskflows.es6')
const people = require('./hub/modules/people.es6')
const organization = require('./hub/modules/organization.es6')
const person = require('./hub/modules/person.es6')
const switcher = require('./hub/modules/switcher.es6')
const todos = require('./hub/modules/todos.es6')
const prototype = require('./hub/modules/prototype.es6')

const views = require('./hub/views.es6')

const access = require('./hub/components/access.es6')
const announcements = require('./hub/components/announcements.es6')
const notifications = require('./hub/components/notifications.es6')
const taskflowHistory = require('./hub/components/taskflow-history.es6')

const formValidation = require('./utils/form-validation.es6')

$(() => {
  // router
  page.base('/hub')
  page('/', app.refresh, dashboard.init)
  page('/:groupId/report/:taskflowId', app.refresh, reports.init)
  page('/:groupId/kanban/:taskflowId/:index?', app.refresh, (ctx, next) => {
    import('./hub/modules/kanban.es6' /* webpackChunkName: "kanban" */).then(
      (kanban) => {
        kanban.init(ctx, next)
      }
    )
  })
  page('/:groupId/bespoke', app.refresh, (ctx, next) => {
    import('./hub/modules/bespoke.es6' /* webpackChunkName: "bespoke" */).then(
      (bespoke) => {
        bespoke.init(ctx, next)
      }
    )
  })
  page(
    '/:groupId/taskflow/:taskflowId/history/:processId/:auditType?',
    app.refresh,
    taskflowHistory.showHistoryInFull
  )
  page('/:baseGroupId/people/:currentGroupId?', app.refresh, people.init)
  page(
    '/:baseGroupId/organization/:currentGroupId?',
    app.refresh,
    organization.init
  )
  page('/:groupId/person/:personId/:baseGroupId?', app.refresh, person.init)
  page(
    '/:compoundId/profiles/:list/:segment/:item?/:id?',
    app.refresh,
    (ctx, next) => {
      import(
        './hub/modules/profiles.es6' /* webpackChunkName: "profiles" */
      ).then((profiles) => {
        profiles.init(ctx, next)
      })
    }
  )
  page('/:groupId/import/:preset?', app.refresh, (ctx, next) => {
    import(
      './hub/modules/importer.es6' /* webpackChunkName: "importer" */
    ).then((importer) => {
      importer.init(ctx, next)
    })
  })
  page(
    '/:groupId/risk/:taskflowId/:assessmentId/:isPrint?',
    app.refresh,
    (ctx, next) => {
      import('./hub/modules/risk.es6' /* webpackChunkName: "risk" */).then(
        (risk) => {
          risk.init(ctx, next)
        }
      )
    }
  )
  page('/:groupId/todos/all', app.refresh, todos.all)
  page('/:groupId/todos/:personId?', app.refresh, todos.init)
  page('/switch/:id?', app.refresh, switcher.init)
  page('/logout', app.logout)

  // Maker/Designer
  page('/:groupId/permissions', app.refresh, (ctx, next) => {
    import(
      './hub/modules/permissions.es6' /* webpackChunkName: "permissions" */
    ).then((permissions) => {
      permissions.init(ctx, next)
    })
  })

  // prototyping...
  page('/prototype/show/:folder/:layout', app.refresh, prototype.showPrototype)

  page({
    dispatch: false,
    hashbang: true,
  })

  // exit routes
  page.exit('/:groupId/report/:taskflowId', reports.onPageExit)
  page.exit('/:groupId/kanban/:taskflowId/:index?', (ctx, next) => {
    import('./hub/modules/kanban.es6' /* webpackChunkName: "kanban" */).then(
      (kanban) => {
        kanban.onPageExit(ctx, next)
      }
    )
  })
  page.exit('/:groupId/bespoke', (ctx, next) => {
    import('./hub/modules/bespoke.es6' /* webpackChunkName: "bespoke" */).then(
      (bespoke) => {
        bespoke.onPageExit(ctx, next)
      }
    )
  })
  page.exit(
    '/:groupId/taskflow/:taskflowId/history/:processId',
    taskflowHistory.onPageExit
  )
  page.exit('/:baseGroupId/people/:currentGroupId?', people.onPageExit)
  page.exit(
    '/:baseGroupId/organization/:currentGroupId?',
    organization.onPageExit
  )
  page.exit('/:groupId/person/:personId/:baseGroupId?', person.onPageExit)
  page.exit(
    '/:compoundId/profiles/:list/:segment/:item?/:id?/',
    (ctx, next) => {
      import(
        './hub/modules/profiles.es6' /* webpackChunkName: "profiles" */
      ).then((profiles) => {
        profiles.onPageExit(ctx, next)
      })
    }
  )
  page.exit('/:groupId/risk/:taskflowId/:assessmentId', (ctx, next) => {
    import('./hub/modules/risk.es6' /* webpackChunkName: "risk" */).then(
      (risk) => {
        risk.onPageExit(ctx, next)
      }
    )
  })
  page.exit('/:groupId/import/:preset?', (ctx, next) => {
    import(
      './hub/modules/importer.es6' /* webpackChunkName: "importer" */
    ).then((importer) => {
      importer.onPageExit(ctx, next)
    })
  })
  page.exit('/:groupId/permissions', (ctx, next) => {
    import(
      './hub/modules/permissions.es6' /* webpackChunkName: "permissions" */
    ).then((permissions) => {
      permissions.onPageExit(ctx, next)
    })
  })
  page.exit('/:groupId/todos/:filter?', todos.onPageExitCard)
  page.exit('/switch', switcher.onPageExit)

  startup()
})

const startup = () => {
  app
    .startup()
    .then(() => {
      return access.renderNavigation()
    })
    .then(() => {
      announcements.showLatest(app.groupId, '', true)
      taskflows.setupEvents()
      reports.setupEvents()
      taskflowHistory.setupEvents()
      people.setupEvents()
      notifications.setupEvents()
      app.setupEvents()

      formValidation.observeEmailFields()
      formValidation.observeTelFields()

      if (window.location.hash != '') {
        page(window.location.hash.substr(2))
      } else {
        page('')
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
