'use strict'

const dayjs = require('dayjs')
const RSVP = require('es6-promise').Promise

const api = require('./../components/api.es6')
const metadata = require('./../components/metadata.es6')
const modalComponent = require('./../../utils/modal.es6')
const views = require('./../views.es6')

export const showLatest = (groupId, from, init = false) => {
  return new RSVP.Promise((resolve, reject) => {
    getList(groupId, from).then((announcements) => {
      if (announcements.length > 0) {
        announcements = announcements.map((announcement) => {
          announcement.displayAt = dayjs(announcement.at).format('D MMMM YYYY')
          return announcement
        })

        $('#reusable-modal')
          .modal({
            duration: 10,
            selector: {
              deny: '.close-modal, .actions .close-modal',
            },
            onVisible: () => {
              $('#reusable-modal .content').html(
                views.render('announcements/announcements', {
                  announcements: announcements,
                })
              )
            },
            onShow: () => {
              $('#reusable-modal .header').html('Product announcements')
              $('#reusable-modal .actions .ui.button.positive').hide()
              $('#reusable-modal .actions .ui.button.close').html('Close')
              modalComponent.resizeHeight('#reusable-modal')
              if (from == '') {
                const latestAnnouncementTs =
                  announcements[0].at != null ? announcements[0].at : ''
                metadata.updateUserMetadata([
                  {
                    key: 'announcements-ts',
                    value: latestAnnouncementTs,
                    valueType: 'string',
                  },
                ])
                localStorage.setItem('announcements-ts', latestAnnouncementTs)
              }
            },
            onHidden: () => {
              $('#reusable-modal .actions .ui.button.close').html("Don't save")
              modalComponent.revertUI('#reusable-modal')
            },
          })
          .modal('show')
          .modal('refresh')
      }
      if (init) setupEvents(groupId)
      resolve()
    })
  })
}
export const getList = (groupId, from) => {
  return new RSVP.Promise((resolve, reject) => {
    if (from == '' && localStorage.getItem('announcements-ts') != null)
      from = localStorage.getItem('announcements-ts')
    api.send(`/apiv3/announcements/${groupId}?from=${from}`).then((data) => {
      resolve(data)
    })
  })
}

export const setupEvents = (groupId) => {
  $('body').on('click', '.announcementsJs', (e) => {
    e.preventDefault()
    showLatest(groupId, dayjs().subtract(1, 'year').toISOString())
  })
}
