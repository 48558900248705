'use strict'

const RSVP = require('es6-promise').Promise
const dayjs = require('dayjs')

const app = require('./app.es6')
const api = require('./../components/api.es6')
const todos = require('./../components/todos.es6')
const analytics = require('./../../utils/analytics.es6')
const views = require('./../views.es6')

let groupId

// Card
export const init = (ctx, next) => {
  document.title = 'To-dos | Kotive'
  groupId = ctx.params.groupId
  const personId =
    ctx.params.personId !== undefined ? ctx.params.personId : 'me'

  renderCard()
    .then(() => {
      const trackingMetadata = {
        hubUrl: window.location.href,
        personId: personId,
      }
      analytics.trackEvent('Viewed todos', trackingMetadata)
      return todos.showCardForPerson(personId)
    })
    .catch((err) => {
      console.log(err)
    })
}

const renderCard = () => {
  return new RSVP.Promise((resolve, reject) => {
    $('#workarea').replaceWith(views.render('todos/dashboard', {}))
    resolve()
  })
}

export const onPageExitCard = (ctx, next) => {
  todos.unsubscribeFromRealtimeChannel()
  next()
}

// Report
export const all = (ctx, next) => {
  document.title = "To-do's Overview Report | Kotive"
  groupId = ctx.params.groupId

  renderCanvas()
    .then(() => {
      const trackingMetadata = { hubUrl: window.location.href }
      analytics.trackEvent('Viewed todos overview', trackingMetadata)
      return fetchAndPrepTodos()
    })
    .then((list) => {
      return addTableData(list)
    })
    .then(() => {
      renderDateRangeFilters()
    })
    .catch((err) => {
      console.log(err)
    })
}

const renderCanvas = () => {
  return new RSVP.Promise((resolve, reject) => {
    $('#workarea').replaceWith(
      views.render('todos/report', {
        groupId: groupId,
        taskflowId: 17571, // hardocde for now, check permissions later
      })
    )
    resolve()
  })
}

const fetchAndPrepTodos = () => {
  return new RSVP.Promise((resolve, reject) => {
    let fromTimestamp = localStorage.getItem('date-range-todos-start')
    if (fromTimestamp === null) {
      fromTimestamp = dayjs().subtract(1, 'year').valueOf()
      localStorage.setItem('date-range-todos-start', fromTimestamp)
    }
    let toTimestamp = localStorage.getItem('date-range-todos-end')
    if (toTimestamp === null) {
      toTimestamp = dayjs().add(1, 'year').valueOf()
      localStorage.setItem('date-range-todos-end', toTimestamp)
    }

    RSVP.all([
      api.send(
        `/apiv3/todos/${groupId}?from=${fromTimestamp}&to=${toTimestamp}&includeChildren=true`
      ),
      api.send(
        `/apiv3/group/${groupId}/all?includeChildren=true&includeSelf=true`
      ),
    ])
      .then(([todos, people]) => {
        const list = []
        const groupsById = _.indexBy(people.groups, (g) => {
          return g.id
        })
        const peopleById = _.indexBy(people.people, (p) => {
          return p.id
        })

        _.each(todos.todos, (todo) => {
          todo.description = `${todo.status} ${todo.label}`
          todo.dueAtHumanized =
            todo.dueAt !== undefined && todo.dueAt != ''
              ? dayjs(todo.dueAt).format('DD MMM YYYY')
              : ''
          todo.assignedToNames = ''
          if (todo.assignedTo !== undefined) {
            todo.assignedTo.forEach((a) => {
              todo.assignedToNames += `<span class="ui label todo-assignedToId-${a}">${peopleById[a].firstname} ${peopleById[a].lastname}</span> `
            })
          }
          todo.updatedByName =
            todo.updatedBy === undefined ||
            peopleById[todo.updatedBy] === undefined
              ? ''
              : `${peopleById[todo.updatedBy].firstname} ${
                  peopleById[todo.updatedBy].lastname
                }`
          todo.groupName = groupsById[todo.groupId].label
          list.push(todo)
        })
        resolve(list)
      })
      .catch((reason) => {
        console.error(reason)
        reject(reason)
      })
  })
}

const addTableData = (tableData) => {
  return new RSVP.Promise((resolve, reject) => {
    $('#workarea img.ui.image.centered').hide()

    $.fn.dataTableExt.sErrMode = 'console'

    const columnsData = []
    columnsData.push({
      title: 'Status',
      data: 'status',
      width: '10px',
      render: (data, type, row) => {
        if (type === 'display') {
          if (data == 'complete')
            return '<span class="ui label green">Complete</span>'
          else if (data == 'overdue')
            return '<span class="ui label orange">Overdue</span>'
          else if (data == 'expired')
            return '<span class="ui label red">Expired</span>'
          return '<span class="ui label">Incomplete</span>'
        }
        return data
      },
    })
    columnsData.push({ title: 'To-do', data: 'label' })
    columnsData.push({
      title: 'Due date',
      data: 'dueAt',
      render: (data, type, row) => {
        if (type === 'display' || type === 'filter')
          return data !== undefined && data != ''
            ? dayjs(data).format('DD MMM YYYY')
            : ''
        return data
      },
    })
    columnsData.push({ title: 'Assigned to', data: 'assignedToNames' })
    columnsData.push({ title: 'Reschedule', data: 'reschedule' })
    columnsData.push({ title: 'Organisation', data: 'groupName' })
    columnsData.push({ title: 'Updated by', data: 'updatedByName' })

    const tbl = $('#datatable').DataTable({
      data: tableData,
      columns: columnsData,
      scrollY: '64vh',
      scrollX: true,
      scrollCollapse: true,
      select: {
        style: 'multi',
        selector: 'td:first-child',
        info: false,
      },
      paging: false,
      processing: true,
      language: {
        search: 'Quickfilter:',
        emptyTable:
          "<span class='yellow-strip'>No to-do's are available...</span>",
        zeroRecords: "No matching to-do's found...",
        infoEmpty: "Showing 0 to 0 of 0 to-do's",
        info: "Showing _START_ to _END_ of _TOTAL_ to-do's",
        infoFiltered: '(filtered from a total of _MAX_)',
      },
      buttons: setButtons(),
      destroy: true, // https://datatables.net/reference/option/destroy
    })

    $('#datatable tr').addClass('top aligned')

    tbl.order([[3, 'desc']]).draw()

    tbl
      .buttons()
      .container()
      .prependTo($('.dataTables_filter'), tbl.table().container())

    resolve()
  })
}

const setButtons = () => {
  const buttons = []

  buttons.push({
    extend: 'csv',
    fieldSeparator: ';',
    text: '<i class="icon fitted download"></i>&nbsp;Download csv',
    className: 'ui button icon green inverted',
    exportOptions: {
      columns: ':not(:last-child)',
      format: {
        body: function (data, row, column, node) {
          return column === 0 ? node.parentNode.id : data
        },
      },
    },
  })

  return buttons
}

const renderDateRangeFilters = () => {
  return new RSVP.Promise((resolve, reject) => {
    let start = parseInt(localStorage.getItem('date-range-todos-start'))
    start = isNaN(start)
      ? dayjs().subtract(1, 'year').format('MMMM D, YYYY')
      : dayjs(start).format('MMMM D, YYYY')

    let end = parseInt(localStorage.getItem('date-range-todos-end'))
    end = isNaN(end)
      ? dayjs().add(1, 'year').format('MMMM D, YYYY')
      : dayjs(end).format('MMMM D, YYYY')

    $('#datatable_filter')
      .parent()
      .parent()
      .children()
      .first()
      .html(
        views.render('reports/date-range', {
          start: start,
          end: end,
        })
      )

    $('#date-range-start').calendar({
      type: 'date',
      endCalendar: $('#date-range-end'),
      onChange: (date, text, mode) => {
        const dateRangeStart = dayjs(text, 'MMMM D, YYYY')
          .startOf('day')
          .valueOf()
        localStorage.setItem('date-range-todos-start', dateRangeStart)
        $('#date-range-filter-report-js').show()
      },
    })

    $('#date-range-end').calendar({
      type: 'date',
      startCalendar: $('#date-range-start'),
      onChange: (date, text, mode) => {
        const dateRangeEnd = dayjs(text, 'MMMM D, YYYY').endOf('day').valueOf()
        localStorage.setItem('date-range-todos-end', dateRangeEnd)
        $('#date-range-filter-report-js').show()
      },
    })
    resolve()
  })
}
